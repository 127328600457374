<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col md="8" sm="6">
        <div>
          <h1>EVALUATION CLOSE OUT</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <customer-info />
    <v-row>
      <v-col cols="12" sm="4">
        <v-radio-group v-model="rep_location" row>
          <label class="mr-5">Rep Visit Location?</label>
          <v-radio label="Yes" value="radio-1"></v-radio>
          <v-radio label="No" value="radio-2"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="4">
        <v-radio-group v-model="bid" row>
          <label class="mr-5">Bid?</label>
          <v-radio label="Yes" value="radio-1"></v-radio>
          <v-radio label="No" value="radio-2"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          label="No Bid Reason"
          v-model="closeout_info.no_bid_reason"
          :items="no_bid_reason_values"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          label="Probability of Close"
          v-model="closeout_info.probability"
          :items="probability_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Perceived Need"
          v-model="closeout_info.perceived_need"
          :items="Perceived_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Perceived Ability to Pay"
          v-model="closeout_info.ability_to_pay"
          :items="ability_to_pay_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Overall Lead Quality"
          v-model="closeout_info.lead_quality"
          :items="lead_quality_values"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          label="Home Style"
          v-model="closeout_info.home_style"
          :items="home_style_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Décor"
          v-model="closeout_info.decor"
          :items="decor_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Cleanliness"
          v-model="closeout_info.cleanliness"
          :items="cleanliness_values"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          label="Yard Maintenance"
          v-model="closeout_info.yard_maintenance"
          :items="yard_maintenance_values"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-textarea
          label="Comments:"
          auto-grow
          outlined
          rows="3"
          row-height="40"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12">
        <div class="text-right">
          <v-btn color="error">CLOSE EVALUATION</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
export default {
  components: { CustomerInfo },
  name: "EvalCloseout.vue",
  data() {
    return {
      probability_values: [
        "0%",
        "10%",
        "20%",
        "30%",
        "40%",
        "50%",
        "60%",
        "70%",
        "80%",
        "90%",
        "100%",
      ],
      Perceived_values: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      ability_to_pay_values: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      lead_quality_values: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      rep_location: "radio-1",
      bid: "radio-1",
      no_bid_reason_values: [],
      perceived_need_values: "",
      home_style_values: [],
      decor_values: [],
      cleanliness_values: [],
      yard_maintenance_values: [],
      closeout_info: {
        probability: "",
        no_bid_reason: "",
        ability_to_pay: "",
        lead_quality: "",
        perceived_need: "",
        home_style: "",
        decor: "",
        cleanliness: "",
        yard_maintenance: "",
      },
    };
  },
};
</script>

<style scoped></style>
